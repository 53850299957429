import React from 'react'
import AdminLayout from '../../components/ContentAdmin'
import AdminSignalList from '../../components/ContentAdmin/SignalTab/List'

export default () => {
  return (
    <AdminLayout>
      <AdminSignalList />
    </AdminLayout>
  )
}
