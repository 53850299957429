import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './style'
import { useTranslation } from 'react-i18next'
import { useState } from '@hookstate/core'
import { CONTENT_ADMIN_ROUTES, ROUTES } from '../../../../constant'
import { SignalAction, SignalState, StrategyAction, StrategyState } from '../../../Strategy/state'
import { getObj } from '../../../../utils/hookState'
import { InputLabel } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import SignalHistoryTab from '../../../Strategy/StrategyDetail/SignalHistoryTab'

export default function AdminSignalList() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const strategyListState = useState(StrategyState.list)
  const pageNoState = useState(SignalState.listPageNo)

  const [strategyId, setStrategyId] = React.useState(
    getObj(strategyListState.data).length > 0 ? getObj(strategyListState.data)[0].id : 0
  )

  useEffect(() => {
    StrategyAction.findAndCountEquity({ status: 'ACTIVE' }, false, 0, Number.MAX_SAFE_INTEGER)().then(() => {
      if (getObj(strategyListState.data).length > 0) {
        setStrategyId(getObj(strategyListState.data)[0].id)
      }
    })
  }, [])

  useEffect(() => {
    SignalAction.findAndCount(strategyId, pageNoState.get())()
  }, [strategyId, pageNoState.get()])

  return (
    <Paper className={classes.paper} elevation={0}>
      <FormControl variant="outlined">
        <InputLabel>Strategy</InputLabel>
        <Select
          className={classes.category}
          value={strategyId}
          onChange={(e) => setStrategyId(e.target.value as number)}
          label="Strategy"
        >
          {getObj(strategyListState.data).map((record) => (
            <MenuItem key={record.id} value={record.id}>
              {record.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        // className={classes.createPostButton}
        variant="contained"
        onClick={() => navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.SIGNAL_CREATE}`)}
      >
        Create Signal
      </Button>

      <SignalHistoryTab strategyId={strategyId} />
    </Paper>
  )
}
