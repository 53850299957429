import React, { useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './style'
import { useTranslation } from 'react-i18next'
import { useState } from '@hookstate/core'
import SignalHistoryTabFooter from './footer'
import { getObj } from '../../../../utils/hookState'
import { SignalAction, SignalState } from '../../state'
import { isScreenBiggerThan, toVnDt } from '../../../../utils/parse'
import { GlobalTableCell, GlobalTableRow } from 'globals/style'
import Typography from '@material-ui/core/Typography'
import { useMobileStyles } from './styleMobile'
import { navigate } from 'gatsby'
import { ROUTES } from '../../../../constant'

export default function SignalHistoryTab({ strategyId }) {
  const classes = useStyles()
  const classesMobile = useMobileStyles()
  const { t, i18n } = useTranslation()

  const signalListState = useState(SignalState.list)
  const pageNoState = useState(SignalState.listPageNo)

  useEffect(() => {
    pageNoState.set(0)
  }, [])

  useEffect(() => {
    SignalAction.findAndCount(strategyId, pageNoState.get())()
  }, [strategyId, pageNoState.get()])

  const headers = ['ID', 'Code', 'Sell Buy Type Short', 'Open Signal', 'Price', 'Profit', 'Created At', 'Event']
  const ProfitCell = ({ row }) => {
    if (row.open_signal?.sell_buy_type === 'BUY') {
      const profit = row.price - row.open_signal?.price
      if (profit >= 0) {
        return (
          <GlobalTableCell className={classes.profitGreen}>
            <Typography className={classes.profitTypoGreen}>
              {+profit.toFixed(2)} (+
              {((profit * 100) / row.open_signal?.price).toFixed(2)}%)
            </Typography>
          </GlobalTableCell>
        )
      } else {
        return (
          <GlobalTableCell className={classes.profitRed}>
            <Typography className={classes.profitTypoRed}>
              {+profit.toFixed(2)} ({((profit * 100) / row.open_signal?.price).toFixed(2)}%)
            </Typography>
          </GlobalTableCell>
        )
      }
    } else {
      // this is sell, so reverse
      const profit = -(row.price - row.open_signal?.price)
      if (profit >= 0) {
        return (
          <GlobalTableCell className={classes.profitGreen}>
            <Typography className={classes.profitTypoGreen}>
              {+profit.toFixed(2)} (+
              {((profit * 100) / row.open_signal?.price).toFixed(2)}%)
            </Typography>
          </GlobalTableCell>
        )
      } else {
        return (
          <GlobalTableCell className={classes.profitRed}>
            <Typography className={classes.profitTypoRed}>
              {+profit.toFixed(2)} ({((profit * 100) / row.open_signal?.price).toFixed(2)}%)
            </Typography>
          </GlobalTableCell>
        )
      }
    }
  }

  const ProfitCellMobile = ({ row }) => {
    if (row.open_signal?.sell_buy_type === 'BUY') {
      const profit = row.price - row.open_signal?.price
      if (profit >= 0) {
        return (
          <GlobalTableCell className={classesMobile.profitGreen}>
            <Typography className={classesMobile.profitTypoGreen}>
              {+profit.toFixed(2)}
              <br />
              (+
              {((profit * 100) / row.open_signal?.price).toFixed(2)}%)
            </Typography>
          </GlobalTableCell>
        )
      } else {
        return (
          <GlobalTableCell className={classesMobile.profitRed}>
            <Typography className={classesMobile.profitTypoRed}>
              {+profit.toFixed(2)}
              <br />({((profit * 100) / row.open_signal?.price).toFixed(2)}%)
            </Typography>
          </GlobalTableCell>
        )
      }
    } else {
      // this is sell, so reverse
      const profit = -(row.price - row.open_signal?.price)
      if (profit >= 0) {
        return (
          <GlobalTableCell className={classesMobile.profitGreen}>
            <Typography className={classesMobile.profitTypoGreen}>
              {+profit.toFixed(2)}
              <br />
              (+
              {((profit * 100) / row.open_signal?.price).toFixed(2)}%)
            </Typography>
          </GlobalTableCell>
        )
      } else {
        return (
          <GlobalTableCell className={classesMobile.profitRed}>
            <Typography className={classesMobile.profitTypoRed}>
              {+profit.toFixed(2)}
              <br />({((profit * 100) / row.open_signal?.price).toFixed(2)}%)
            </Typography>
          </GlobalTableCell>
        )
      }
    }
  }

  const MobileTableCell = ({ children }) => (
    <GlobalTableCell className={classesMobile.tableCell} align={'center'}>
      <Typography className={classesMobile.cellTypo}>{children}</Typography>
    </GlobalTableCell>
  )

  return isScreenBiggerThan('md') ? (
    <Paper className={classes.paper} elevation={0}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((label) => (
              <TableCell key={label} align={'center'}>
                <Typography className={classes.headerTypo}>{t(label)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getObj(signalListState.data).length > 0 &&
            getObj(signalListState.data).map((row: any, taskIndex) => (
              <GlobalTableRow key={row.id}>
                <GlobalTableCell align={'center'}>{row.id}</GlobalTableCell>
                <GlobalTableCell
                  className={classes.codeCell}
                  align={'center'}
                  onClick={() => navigate(`${ROUTES.CODE}/${row.code}`)}
                >
                  {row.code}
                </GlobalTableCell>
                <GlobalTableCell align={'center'}>{t(row.sell_buy_type)}</GlobalTableCell>
                <GlobalTableCell align={'center'}>{row.open_signal?.id}</GlobalTableCell>
                <GlobalTableCell align={'center'}>{row.price}</GlobalTableCell>

                {row.open_signal == null ? <GlobalTableCell /> : <ProfitCell row={row} />}

                <GlobalTableCell align={'center'}>{toVnDt(row.created_at)}</GlobalTableCell>
                <GlobalTableCell align={'center'}>{row.dividend_info}</GlobalTableCell>
              </GlobalTableRow>
            ))}
        </TableBody>
        <SignalHistoryTabFooter />
      </Table>
    </Paper>
  ) : (
    <Paper className={classesMobile.paper} elevation={0}>
      <Table className={classesMobile.table}>
        <TableHead>
          <TableRow>
            {headers.map((label) => (
              <TableCell className={classesMobile.tableCell} key={label} align={'center'}>
                <Typography className={classesMobile.headerTypo}>{t(label)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getObj(signalListState.data).length > 0 &&
            getObj(signalListState.data).map((row: any, taskIndex) => (
              <GlobalTableRow key={row.id}>
                <MobileTableCell>{row.id}</MobileTableCell>
                <MobileTableCell>{row.code}</MobileTableCell>
                <MobileTableCell>{t(row.sell_buy_type)}</MobileTableCell>
                <MobileTableCell>{row.open_signal?.id}</MobileTableCell>
                <MobileTableCell>{row.price}</MobileTableCell>

                {row.open_signal == null ? (
                  <GlobalTableCell className={classesMobile.tableCell} />
                ) : (
                  <ProfitCellMobile row={row} />
                )}

                <MobileTableCell>{toVnDt(row.created_at)}</MobileTableCell>
                <MobileTableCell>{row.dividend_info}</MobileTableCell>
              </GlobalTableRow>
            ))}
        </TableBody>
        <SignalHistoryTabFooter />
      </Table>
    </Paper>
  )
}
