import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    pagination: {},
    customPagination: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    codeCell: {
      cursor: 'pointer'
    },
    headerTypo: {
      fontSize: '14px',
      fontWeight: 700
    },
    tableFooter: {
      // left: 0,
      // bottom: 0, // <-- KEY
      // zIndex: 2,
      // position: 'sticky'
    },
    titleTypo: {
      marginLeft: theme.spacing(2),
      cursor: 'pointer',
    },
    category: {
      width: theme.spacing(40),
      marginBottom: theme.spacing(2),
    },
    profitTypoGreen: {
      fontWeight: 700,
      color: 'rgb(22,154,79)',
      fontSize: '13px',
      fontFamily: ['OpenSans', 'sans-serif'].join(','),
    },
    profitTypoRed: {
      fontWeight: 700,
      color: 'rgb(187,43,28)',
      fontSize: '13px',
      fontFamily: ['OpenSans', 'sans-serif'].join(','),
    },
    profitGreen: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgb(198,242,194)',
    },
    profitRed: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgb(255,197,191)',
    },
  })
)
